*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
html{
    
}
body{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

/* .navbar-inn{
    
    display: flex;
    justify-content: space-between;
    

} */

.flex-grow-1{
    flex: 1;
    min-height: 100%;
    position: relative;
}

/* CSS for logo */
.navbar-brand {
    width: 70px;
    height: 75px;
    justify-content: start;
    padding: 0;
}

.navbar-brand img {
    width: 100%;
    max-height: 100%;
    object-fit: contain;
    margin: 0;
    padding: 0;
}
.navbar .nav-item{
    padding: 0 10px ;
}
.navbar .nav-link{
    padding: 0;
    margin: 0;
    
}
.navbar{
    padding: 0 !important;
}
.container .navbar-inn{
    padding: 0 15px;
}

.active {
    display: block;
    cursor: pointer;
    color: rgb(61, 158, 200) !important;
    text-decoration-line: underline;
    text-decoration-thickness: 3px;
    text-underline-offset: 14px;
    
    
}
.nav-item{
    
}
/*css for detials box*/
.box-inn{
    position: relative;
    color: #022541;
    text-transform: capitalize;
    padding: 5px;
    margin-bottom: 20px;

}
.box-inn::before{
    content: '';
    background: linear-gradient(to top right, #EC0B19, #022541);
    position: absolute;
    top: 0;
    left: 0; 
    right: 0;
    bottom: 0;
    z-index: -1;
    margin: -4px;  
    box-shadow: 3px 3px 3px gray;
}
.box:hover{
    scale: 1.02;
}
.charts{
    max-height: 600px;

    .barchart{
        height: 600px;
    }
}
.icon{
    position: absolute;
    top: 0px;
    right: 10px;
    font-size: 24px;   
}

/*NextEvent*/
/* .home{
    position: relative;
}
.card{
    position:absolute !important;
    right: 120px;
} */


/*calender css*/
.calendar {
    max-width: 100%;
    margin: 0 auto;
    border: 1px solid #ccc; /* Border around the calendar */
    border-radius: 8px; /* Optional: rounded corners */
    padding: 16px; /* Padding inside the calendar */
    background-color: #fff; /* Optional: background color */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Optional: shadow for better visibility */
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: end;
    margin-bottom: 1rem;
    position: relative;
  }
  

  .header .icon {
    cursor: pointer;
    position: absolute;

  }
  .header .icon-righ{
    right: 10px;
  }
  .header .icon-left{
    left: 10px;
  }
  
  .row {
    display: flex;
  }
  
  .days {
    margin-bottom: 1rem;
  }
  
  .col {
    flex: 1;
    text-align: center;
  }
  
  .col-center {
    text-align: center;
  }
  
  .cell {
    cursor: pointer;
    min-height: 50px;
    position: relative;
    transition: background-color 0.3s ease; /* Add transition for smooth effect */
  }
  
  .cell.disabled {
    background-color: #f0f0f0;
    color: #ccc;
  }
  
  .cell:hover {
    background-color: #e0e0e0; /* Light grey background on hover */
  }
  
  .cell.selected {
    background-color: #4caf50;
    color: white;
  }
  
  .cell .number {
    position: absolute;
    top: 5px;
    right: 5px;
  }
  
  .view-switch {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }
  
  .view-switch button {
    margin: 0 5px; /* Space between buttons */
    padding: 10px 20px; /* Padding inside buttons */
    border: none;
    border-radius: 4px; /* Rounded corners */
    background-color: #4caf50; /* Default background color */
    color: white; /* Default text color */
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition for background color */
  }
  
  .view-switch button:hover {
    background-color: #45a049; /* Darker green on hover */
  }
  
  .view-switch button:active {
    background-color: #3e8e41; /* Even darker green on active */
  }
  
  .agenda {
    display: flex;
    flex-direction: column;
  }
  
  .agenda-item {
    border-bottom: 1px solid #ddd;
    padding: 1rem 0;
  }
  
  .agenda-date {
    font-weight: bold;
  }
  
  .agenda-events {
    margin-top: 0.5rem;
  }

.height-5{
    height: 5px;
  }
  .height-10{
    height: 10px;
  }




/*media query*/
/*css for input*/
@media screen and (max-width: 900px){
    .container-fluid{
        flex-direction: column;
        align-items: flex-start;
    }
    .container-fluid h1{
        margin-bottom: 10px;
    }
    .form-control{
        width: 100%;
    }
}

@media screen and (max-width: 600px){
    .footer{
    text-align: center;
}
}
