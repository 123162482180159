.ucc-red {color:#EC0B19}
.ucc-blue {color:#022541}

.united-bg{background-color: #EC0B19;}
.elite-bg{background-color: #006769;}
.shaheen-bg {background-color: #40A578;}
.superstar-bg {background-color: #9DDE8B;}
.youth-bg {background-color: #E6FF94;}

.text-white{color:#fff;}

.test{border: #EC0B19 1px solid;}

.tag{color:white;background-color: #022541; text-transform:uppercase; padding: 5px; }

img{
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.logo-box {
    padding: 20px;
}

/* EVENT BOX */
.events-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.event-box{
    width: 100%;
    /*max-width: 600px;*/    
}
.event-box-inner{
    display: flex;
    flex-direction: row;
    align-content: center;
}
/*ccs for SeriesSchedule*/
.event-date{    
    min-width: 100px;
    border-right: #006769 solid 1px;
}
.event-details{
    margin-left: 10px;
    position: relative;
}
.event-sub{
    align-content: right;
    background-color: #40A578;
}

.event-comments{   
    color: #EC0B19;
    /* position: absolute;
    bottom: -35px;
    font-weight: 500; */
}